import { Row, Col, Card, CardBody, Label } from 'reactstrap';
import SideNav from './components/sidenav';
import AuthContext from './contexts/auth'
import { HeadFC } from "gatsby";
import React from 'react';

import './index.scss';

const AdminLogin = () => {
  return (
    <AuthContext>
      <div className="admin-container d-flex flex-row w-100">
        <SideNav
          activeMenu='dashboard'
        />
        <Row className="admin-page-content d-flex flex-column align-items-center">
          <Col md={11}>
            <Card className="w-100 p-4 mb-5">
              <CardBody>
                <h4>Estadísticas</h4>

                <Row className="mt-4">
                  <Col md={3}>
                    <Card className="p-3 background-light-blue dashboard-card mb-4">
                      <div className="bg-gradient-red rounded-circle shadow dashboard-icon">
                        <i className="bi bi-eyeglasses"></i>
                      </div>
                      <b>Cantidad de noticias</b>
                    </Card>
                  </Col>
                  <Col md={3}>
                    <Card className="p-3 background-light-blue dashboard-card mb-4">
                      <div className="bg-gradient-orange rounded-circle shadow dashboard-icon">
                        <i className="bi bi-cash-coin"></i>
                      </div>
                      <b>Visitas únicas</b>
                    </Card>
                  </Col>
                  <Col md={3}>
                    <Card className="p-3 background-light-blue dashboard-card mb-4">
                      <div className="bg-gradient-green rounded-circle shadow dashboard-icon">
                        <i className="bi bi-person-badge"></i>
                      </div>
                      <b>Visitas</b>
                    </Card>
                  </Col>
                  <Col md={3}>
                    <Card className="p-3 background-light-blue dashboard-card mb-4">
                      <div className="bg-gradient-blue rounded-circle shadow dashboard-icon">
                        <i className="bi bi-capslock-fill"></i>
                      </div>
                      <b>Actualizaciones</b>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </AuthContext>
  );
}

export default AdminLogin;

export const Head: HeadFC = () => <title>LaBotana : Admin - Dashboard</title>
